// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-tech-js": () => import("./../../../src/pages/portfolio/tech.js" /* webpackChunkName: "component---src-pages-portfolio-tech-js" */),
  "component---src-pages-portfolio-visuals-index-js": () => import("./../../../src/pages/portfolio/visuals/index.js" /* webpackChunkName: "component---src-pages-portfolio-visuals-index-js" */),
  "component---src-pages-terminal-js": () => import("./../../../src/pages/terminal.js" /* webpackChunkName: "component---src-pages-terminal-js" */),
  "component---src-templates-tech-js": () => import("./../../../src/templates/tech.js" /* webpackChunkName: "component---src-templates-tech-js" */),
  "component---src-templates-update-js": () => import("./../../../src/templates/update.js" /* webpackChunkName: "component---src-templates-update-js" */),
  "component---src-templates-visual-js": () => import("./../../../src/templates/visual.js" /* webpackChunkName: "component---src-templates-visual-js" */)
}

